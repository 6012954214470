import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import './App.css'; // Import your CSS for styling (if applicable)
import GmailAuth from './GmailAuth'; // Import your component
import EmailLabeling from './EmailLabeling'; // Import the component for the other page

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GmailAuth />} />
        <Route path="/email-labeling" element={<EmailLabeling />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;