import React, { useState, useEffect, useRef } from 'react';
import { Table, Layout, Spin,Tag } from 'antd';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
const { Content, Footer } = Layout; // Import Content and Footer from Layout
// import { Cookies } from 'universal-cookie';
// require('dotenv').config();

const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Snippet',
      dataIndex: 'snippet',
      key: 'snippet',
    },
  ];
// depending on where the front is running, set the backend URL
const isLocalhost = window.location.host === 'localhost:3000';
const backend = isLocalhost === true? 'https://localhost:8081' :'https://email-labeler-backend-3d286b1febfb.herokuapp.com';

// const cookies = new Cookies();

function EmailLabeling() {
    // data variables
    const [emails, setEmails] = useState([]);
    const [labelData, setLabelData] = useState([]);
    const [customLabels, setCustomLabels] = useState([]);
    const [labelsNone, setLabelsNone] = useState(false);
    // state variables
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Use the navigate function from useNavigate
    const isFirstRender = useRef(true); // Ref to track the first render
    const [cookieSaved, setCookieSaved] = useState(false);
    const isMounted = useRef(true); // Declare isMounted outside using useRef
    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    // use an effect to check if the cookie is set and valid with /api/auth/validate
    useEffect(() => {
            const checkCookieAndURLForJWT = async () => {   
            const jwtCookie = document.cookie.split('; ').find(row => row.startsWith('jwt='));
            const token = jwtCookie ? jwtCookie.split('=')[1] : null;

            if (token) {
                // Validate the cookie with the backend
                const response = await fetch(`${backend}/api/auth/validate`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });

                if (response.ok) {
                    setCookieSaved(true);
                } else {
                    document.cookie = 'jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None;';
                    return navigate('/'); // Go to login if validation fails
                }
            } else {
                const params = new URLSearchParams(window.location.search);
                const jwt = params.get('jwt');

                if (jwt) {
                    document.cookie = `jwt=${jwt}; path=/; secure; SameSite=None;`;
                    setCookieSaved(true);
                } else {
                    setError('JWT not found.');
                    return navigate('/');
                }
            }
        };

        if (!cookieSaved) {
            checkCookieAndURLForJWT();
        }
    }, [cookieSaved, navigate]);
    useEffect(() => {
        if (cookieSaved && isFirstRender.current) {
            isFirstRender.current = false;
            console.log('SET isFirstRender to false');
    
            const fetchEmails = async () => {
                let jwtCookie, jwtToken;
    
                while (!jwtCookie) {
                    console.log('no jwt cookie found yet')
                    jwtCookie = document.cookie.split('; ').find(row => row.startsWith('jwt='));
                    jwtToken = jwtCookie ? jwtCookie.split('=')[1] : null;
    
                    if (!jwtToken) {
                        await new Promise(resolve => setTimeout(resolve, 100));
                    }
                }
    
                if (!jwtToken) {
                    if (isMounted.current) {
                        console.error('JWT token not found');
                        setError('Authentication token is missing.');
                        setLoading(false);
                    }
                    return;
                }
    
                try {
                    const response = await fetch(`${backend}/api/emails`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${jwtToken}`,
                            'Content-Type': 'application/json'
                        },
                    });
    
                    if (response.status === 429) {
                        if (isMounted.current) {
                            throw new Error('You have hit your email limit.');
                        }
                    } else if (!response.ok) {
                        if (isMounted.current) {
                            console.error('Failed to fetch emails:', response.status);
                            console.log(response);
                            throw new Error('Failed to fetch emails');
                        }
                    }
    
                    const data = await response.json();
                    console.log(data);
                    if (isMounted.current) {
                        if (data.length === 0) {
                            setError('No unlabeled emails found or you have no emails readable.');
                            return;
                          } else if (response.status === 429) {
                            setError('You have hit your email limit.');
                            return;
                          } else if (response.status === 200) {
                            setEmails(data); 
                          }else{
                            setError('An unexpected error occurred while fetching emails.');
                            return;
                          }
                    }
    
                    try {
                        const labelsResponse = await fetch(`${backend}/api/labelEmails`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${jwtToken}`
                            },
                            body: JSON.stringify({ emails: data }),
                        });
    
                        if (!labelsResponse.ok) {
                            if (isMounted.current) {
                                throw new Error('Failed to label emails');
                            }
                        }
    
                        const { labels, customLabels } = await labelsResponse.json();
    
                        if (isMounted.current) {
                            setLabelData(labels);
                            setCustomLabels(customLabels);
                            console.log("Custom Labels:", customLabels);
                            console.log(labelData);
                        }
                    } catch (err) {
                        if (isMounted.current) {
                            console.error('Error labeling emails:', err);
                            setError(err.message);
                        }
                    }
                } catch (err) {
                    if (isMounted.current) {
                        console.error('Error fetching emails:', err);
                        setError(err.message);
                    }
                } finally {
                    if (isMounted.current) {
                        setLoading(false);
                    }
                }
            };
    
            fetchEmails(); // Call the async function within the effect
        }
    }, [cookieSaved]); // useEffect now depends on jwtCookieSet

    // Use useEffect to update labelsNone based on labelData
    useEffect(() => {
        const allLabelsNull = labelData.every(label => label === null || label.toLowerCase() === 'none');
        setLabelsNone(allLabelsNull);
    }, [labelData]); // This useEffect runs whenever labelData changes

    
    // Map email snippets and labels together
    const emailLabelPairs = emails.map((email, index) => ({
        snippet: email.snippet,
        label: labelData[index] || 'None' // Use 'None' if label is null
    }));

    const filteredEmailLabelPairs = emailLabelPairs.filter(pair => pair.label !== 'None');

    return (
        <Layout className='layout'>
            <Content>
            <div>
                <h1>Email Snippets and Labels</h1>
                {/* Display customLabels here with distinct styling */}
                <h2>Custom Labels:</h2>
                {customLabels && customLabels.length > 0 ? ( // Check if customLabels exist and have items
                        customLabels.map(label => (
                            <Tag key={label.name} style={{ marginBottom: '8px' }}>
                                {label.name}
                            </Tag>
                        ))
                    ) : (
                        !error && !loading && <p>No custom labels found.</p> // Display a message if there are no custom labels
                )}

                {loading ? ( // Conditionally render loading indicator
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <Spin size="large" /> 
                        </div>
                    ) : error ? (
                        <p>{error}</p>
                    ) : labelsNone ? (
                        <p>
                            Your last 30 emails are already labeled or there was not a good fit for the emails in your labels. Try setting more specific labels or come back another time when you unlabeled emails in your inbox.
                        </p>
                    ) : (
                        <Table 
                            dataSource={filteredEmailLabelPairs} 
                            columns={columns} 
                            pagination={false} 
                        />
                    )}
            </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}> {/* Add the Footer */}
                Copyright © David Warshawsky 2024
            </Footer>
        </Layout>
    );
}

export default EmailLabeling;