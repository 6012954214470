import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import Link
import { Form, Input, Button, Typography, Alert, Layout } from 'antd';
import './GmailAuth.css';
import './index.css';
import {checkExistingJWT, encryptOpenAIKey} from './authUtils'; // Import the validateJwtToken function

const { Content, Footer } = Layout; // Import Content and Footer from Layout
const { Title } = Typography;

const isLocalhost = window.location.host === 'localhost:3000';
const backend = isLocalhost === true
  ? 'https://localhost:8081' 
  :'https://email-labeler-backend-3d286b1febfb.herokuapp.com';



function GmailAuth() {
  const [showPage, setShowPage] = useState(false);
  const [publicKey, setPublicKey] = useState(null);
  const navigate = useNavigate(); // Use the navigate function from useNavigate
  const [authUrl, setAuthUrl] = useState(''); 
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect( () => {
    const initialize = async () => {
      await checkExistingJWT(backend, navigate, setPublicKey, setShowPage); // Check JWT on component mount
    };
    initialize();
  }, [navigate]); // Include navigate in the dependency array


  const handleSubmit = async (values) => {
    console.log("Authenticate button clicked!"); 

    // Access the OpenAI key from the 'values' object
    const openAIKey = values.openAIKey;
    console.log('OpenAI key:', openAIKey);

    // Clear previous errors
    setErrorMessage('');
    if (publicKey) { // Only proceed if the public key is available
      const encryptedOpenAIKey = await encryptOpenAIKey(publicKey, openAIKey);
      console.log('Encrypted OpenAI key:', encryptedOpenAIKey);
      try {
        const response = await fetch(backend + '/api/authenticate', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ encryptedOpenAIKey })});

        if (response.ok) {
          const data = await response.json();
          setAuthUrl(data.authUrl);
          // Listen for JWT and redirect after Google OAuth flow
          window.open(data.authUrl, '_self'); // Opens the Google OAuth URL
        } else {
          const errorData = await response.json(); 
          setErrorMessage(errorData.error || 'Authentication failed.');
        }
      } catch (error) {
        console.error('Error in handleSubmit:', error); 
        setErrorMessage('An error occurred. Please try again.');
      }
    };
  };

  return (
    showPage && <Layout className='layout'>
      <Content>
      <div className="main">
      <div className="container">
        <Title className="title">Email Labeling</Title>
        { !authUrl &&
        <Form className="form" onFinish={handleSubmit} layout="vertical">
        <Form.Item 
          label="OpenAI API Key"
          name="openAIKey" // Add the 'name' prop
          rules={[{ required: true, message: 'Please enter your OpenAI key' }]} // Basic validation
        >
          <Input.Password 
            placeholder="Enter OpenAI Key"
          />
        </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login with Google
            </Button>
          </Form.Item>

          {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
        </Form>
      }
      </div>

      
      </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}> {/* Add the Footer */}
      Copyright © David Warshawsky 2024
      </Footer>
    </Layout>
);
}

export default GmailAuth;
