import JSEncrypt from 'jsencrypt'; // Import the RSA encryption library


const fetchPublicKey = async (backend,setPublicKey) => {
    try {
        const response = await fetch(`${backend}/api/get-public-key`);
        console.log(response);
        if (response.ok) {
        console.log('Public key fetched successfully');
        const data = await response.json();
        setPublicKey(data.publicKey);
        } else {
        // Handle error fetching public key
        console.error('Error fetching public key:', response.status);
        }
    } catch (error) {
        // Handle error
        console.error('Error fetching public key:', error);
    }
};


const validateJwtToken = async (token,backend,navigate,setShowPage) => {
    try {
        const response = await fetch(`${backend}/api/auth/validate`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        if (response.ok) {
            return navigate('/email-labeling'); // Proceed to the Email Labeling component
        } else {
            console.error('JWT validation failed');
            document.cookie = 'jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT'; // Delete the JWT cookie
            setShowPage(true);
        }
    } catch (err) {
        // delete the JWT cookie if validation fails
        console.error('Error validating JWT:', err);
        document.cookie = 'jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT'; // Delete the JWT cookie
        setShowPage(true);
    }
};

const checkExistingJWT = async (backend, navigate, setPublicKey, setShowPage) => {
    const jwtCookie = document.cookie.split('; ').find(row => row.startsWith('jwt='));
    const token = jwtCookie ? jwtCookie.split('=')[1] : null;
    if (token) {
        // Validate the existing JWT token
        validateJwtToken(token,backend,navigate,setShowPage);
    }
    if (!token) {
        // JWT is not present, stay on the authentication page
        setShowPage(true);
        await fetchPublicKey(backend, setPublicKey); // Fetch public key as needed 
    }
};


const encryptOpenAIKey = async (publicKey, openAIKey) => {
    try {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      const encryptedOpenAIKey = await encryptor.encrypt(openAIKey);
      return encryptedOpenAIKey;
    } catch (error) {
      console.error('Error encrypting OpenAI key:', error);
      throw error;
    }
};

export { validateJwtToken,fetchPublicKey,checkExistingJWT, encryptOpenAIKey };